import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Checkbox, Box } from '@mui/material';

interface ActionAreaCardProps {
    imageLink: string;
    title: string;
    isSelected: boolean;
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void; 
    onSelect: () => void;
}

const ActionAreaImagesCard: React.FC<ActionAreaCardProps> = ({ imageLink, title, isSelected, onClick, onSelect }) => {
    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardActionArea component="a" onClick={onClick}> {/* Definir o componente como <a> */}
                <CardMedia
                    component="img"
                    height="140"
                    image={imageLink}
                    alt={title}
                />
                <CardContent>
                    <Box display="flex" alignItems="center">
                        <Checkbox
                            checked={isSelected}
                            onChange={onSelect}
                            color="primary"
                            disableRipple
                        />
                        <Typography gutterBottom variant="h5" component="div" align="center">
                            {title}
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default ActionAreaImagesCard;
