import React, { useState } from "react";
import "react-photo-view/dist/react-photo-view.css";
import { PhotoSlider } from "react-photo-view";

const FullScreenIcon: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    const [fullscreen, setFullscreen] = useState(false);

    React.useEffect(() => {
        document.onfullscreenchange = () => {
            setFullscreen(Boolean(document.fullscreenElement));
        };
    }, []);

    return (
        <svg
            className="PhotoView-Slider__toolbarIcon"
            fill="white"
            width="44"
            height="44"
            viewBox="0 0 768 768"
            onClick={onClick}
        >
            {fullscreen ? (
                <path d="M511.5 256.5h96v63h-159v-159h63v96zM448.5 607.5v-159h159v63h-96v96h-63zM256.5 256.5v-96h63v159h-159v-63h96zM160.5 511.5v-63h159v159h-63v-96h-96z" />
            ) : (
                <path d="M448.5 160.5h159v159h-63v-96h-96v-63zM544.5 544.5v-96h63v159h-159v-63h96zM160.5 319.5v-159h159v63h-96v96h-63zM223.5 448.5v96h96v63h-159v-159h63z" />
            )}
        </svg>
    );
};

function toggleFullScreen() {
    if (document.fullscreenElement) {
        document.exitFullscreen();
    } else {
        const element = document.querySelector(".PhotoView-Portal");
        if (element) {
            element.requestFullscreen();
        }
    }
}

interface ReaderProps {
    images: string[];
    initialIndex: number;
    onClose: () => void;
}

const Reader: React.FC<ReaderProps> = ({ images, initialIndex, onClose }) => {
    const [index, setIndex] = React.useState(initialIndex);
    const [visible, setVisible] = React.useState(true);

    return (
        <div>
            <PhotoSlider
                images={images.map((item, index) => ({
                    src: item,
                    key: index
                }))}
                loop={true}
                visible={visible}
                onClose={() => { onClose() }}
                index={index}
                onIndexChange={setIndex}
                toolbarRender={() => (
                    <div>
                        <FullScreenIcon onClick={toggleFullScreen} />
                    </div>
                )}
            />
        </div>
    );
};

export default Reader;
