import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { Typography } from '@mui/material';

interface CircularIndeterminateProps {
    loading: boolean;
    value: number;
    sx?: SxProps;
}

const CircularProgressIndeterminate: React.FC<CircularIndeterminateProps> = ({ loading, value, sx }) => {
    if (!loading) return null;

    return (
        <Box sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.01)',
            zIndex: 1300,
            ...sx,
        }}>
            <CircularProgress />
            <Typography
                variant="caption"
                component="div"
                color="text.secondary"
            >{`${Math.round(value)}%`}</Typography>
        </Box>
    );
};

export default CircularProgressIndeterminate;