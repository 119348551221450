import React, { useState, useEffect } from 'react';
import './assets/css/App.css'
import { createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StorieCards from './pages/Stories';
import PagesCard from './pages/Album';
import StorieManager from './pages/Stories/Create'; import AlbumService from './services/album';
import { AlertNotification } from './components/alert';
import CircularIndeterminate from './components/progress';
import UploadPages from './pages/Stories/Pages';
import UserManager from './pages/User';
import Authenticate from './pages/Authenticate';
import PageValidate from './pages/Stories/Validate';
import UploadNoTextPages from './pages/Stories/NoTextPages';
import Unique from './pages/Unique';

const defaultTheme = createTheme();

interface AlertSettingsProps {
  message: string;
  type: 'error' | 'success' | 'info' | 'warning';
}

function App() {
  const [pageLoaded, setPageLoaded] = useState(false);
  const [imagesPosts, setImagesPosts] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alertSettings, setAlertSettings] = useState<AlertSettingsProps>({
    message: '',
    type: 'success'
  });

  const getStoriesAsync = async () => {
    try {
      const response = await AlbumService.getStories();
      setImagesPosts(response.data)
    } catch (error) {
      console.error(error);
      setAlertSettings({
        message: error as string,
        type: 'error'
      });
      setOpenAlert(true);
    }
    setLoading(false);
  }

  useEffect(() => {
    getStoriesAsync();
  }, []);

  return (
    <>
      <CircularIndeterminate loading={loading} sx={{ mt: 2 }} />
      <AlertNotification
        message={alertSettings.message}
        type={alertSettings.type}
        alertStatus={openAlert}
        setAlertStatus={setOpenAlert} />
      <Router>
        <Routes>
          <Route path='/*' element={<StorieCards />}></Route>
          <Route path='/Read' element={<PagesCard />}></Route>
          <Route path='/Create' element={<StorieManager />}></Route>
          <Route path='/Upload' element={<UploadPages />}></Route>
          <Route path='/UploadNoText' element={<UploadNoTextPages />}></Route>
          <Route path='/User' element={<UserManager />}></Route>
          <Route path='/Validate' element={<PageValidate />}></Route>
          <Route path='/Authenticate' element={<Authenticate />}></Route>
          <Route path='/Unique' element={<Unique />}></Route>
        </Routes>
      </Router>
    </>
  )
}

export default App;
