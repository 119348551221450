import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Container, CssBaseline, FormControl, Grid, Input, InputAdornment, InputLabel, TablePagination, Button, SxProps } from '@mui/material';
import Header from '../../components/header';
import MainFeaturedPostWrapper from '../Home/components/MainFeaturedPostWrapper';
import mainFeaturedPost from '../Home/components/mainFeaturedPost';
import ActionAreaImagesCard from '../../components/imagesCard/imagesCard';
import Reader from '../Reader';
import sections from '../../utils/menu';
import CircularIndeterminate from '../../components/progress';
import { AlertNotification } from '../../components/alert';
import AlbumService from '../../services/album';
import { getFromSessionStorage } from '../../utils/session';
import BaseUrl from '../../utils/Api';
import axios, { AxiosError } from 'axios';
import CircularProgressIndeterminate from '../../components/progressPercent';

const defaultTheme = createTheme();

interface AlbumModel {
    pages: ImagesPost[];
}

interface ImagesPost {
    name: string;
    imagem: string;
}

interface AlertSettingsProps {
    message: string;
    type: 'error' | 'success' | 'info' | 'warning';
}

interface CircularIndeterminateProps {
    loading: boolean;
    value: number;
    sx?: SxProps;
}

const PagesCard: React.FC = () => {
    const [filter, setFilter] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(6);
    const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
    const [openReader, setOpenReader] = useState(false);
    const [imagesPosts, setImagesPosts] = useState<ImagesPost[]>([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(true);
    const [circularProcess, setCircularProcess] = useState<CircularIndeterminateProps>();
    const [alertSettings, setAlertSettings] = useState<AlertSettingsProps>({
        message: '',
        type: 'success'
    });
    const [selectedImageNames, setSelectedImageNames] = useState<string[]>([]);

    const filteredPosts = imagesPosts.filter(post =>
        post.name.toLowerCase().includes(filter.toLowerCase())
    );

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleImageClick = (index: number, event: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>) => {
        if (!(event.target as HTMLElement).closest('input[type="checkbox"]')) {
            setSelectedImageIndex(page * rowsPerPage + index);
            setOpenReader(true);
        }
    };

    const handleCloseReader = () => {
        setOpenReader(false);
    };

    const handleImageSelect = (index: number) => {
        const selectedImage = filteredPosts[page * rowsPerPage + index];
        const isSelected = selectedImageNames.includes(selectedImage.name);
        if (isSelected) {
            setSelectedImageNames(prevSelectedImageNames => prevSelectedImageNames.filter(name => name !== selectedImage.name));
        } else {
            setSelectedImageNames(prevSelectedImageNames => [...prevSelectedImageNames, selectedImage.name]);
        }
    };

    const handleDownload = async () => {
        try {
            const storieName = getFromSessionStorage("storieName") as string;
            setLoading(true);
    
            const response = await AlbumService.postStorieDownload(storieName, selectedImageNames);
            const downloadPath = response.data; 
            const downloadLink = `${BaseUrl}${downloadPath}`;
    
            const a = document.createElement('a');
            a.href = downloadLink;
            a.download = `${storieName}.zip`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            const axiosError = error as AxiosError;
            const statusCode = axiosError.response?.status ?? null;
            const errorMessage = axiosError.message;
    
            setAlertSettings({
                message: `Failed to download images. ${errorMessage}`,
                type: 'error'
            });
            setOpenAlert(true);
        }
    
        setLoading(false);
    };
    
    const handleDownloadNoTexto = async () => {
        try {
            const storieName = getFromSessionStorage("storieName") as string;
            setLoading(true);
    
            const response = await AlbumService.postStorieNoTextDownload(storieName, selectedImageNames);
            const downloadPath = response.data; 
            const downloadLink = `${BaseUrl}${downloadPath}`;
    
            const a = document.createElement('a');
            a.href = downloadLink;
            a.download = `${storieName}-NoText.zip`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            const axiosError = error as AxiosError;
            const statusCode = axiosError.response?.status ?? null;
            const errorMessage = axiosError.message;
    
            setAlertSettings({
                message: `Failed to download images. ${errorMessage}`,
                type: 'error'
            });
            setOpenAlert(true);
        }
    
        setLoading(false);
    };
    
    const getStorieAsync = async () => {
        try {
            const storieName = getFromSessionStorage("storieName");
            const response = await AlbumService.getStorieRead(storieName as string);
            setImagesPosts(response.data.storiePages);
        } catch (error) {
            const axiosError = error as AxiosError;
            const statusCode = axiosError.response?.status ?? null;
            const errorMessage = axiosError.response?.data as string;

            setAlertSettings({
                message: errorMessage,
                type: statusCode == 419 ? 'warning' : 'error'
            });
            setOpenAlert(true);
        }
        setLoading(false);
    };

    useEffect(() => {
        getStorieAsync();
    }, []);

    return (
        <>
            <CircularProgressIndeterminate
                loading={circularProcess?.loading as boolean}
                value={circularProcess?.value as number}
                sx={{ mt: 2, backgroundColor: 'white' }} />
            <CircularIndeterminate loading={loading} sx={{ mt: 2 }} />
            <AlertNotification
                message={alertSettings.message}
                type={alertSettings.type}
                alertStatus={openAlert}
                setAlertStatus={setOpenAlert} />
            <ThemeProvider theme={defaultTheme}>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header title="Welcome" sections={sections} />
                    <main>
                        <MainFeaturedPostWrapper post={mainFeaturedPost} />
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                pt: 1,
                                pb: 1,
                            }}
                        />
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                pt: 1,
                                pb: 1,
                                textAlign: 'center',
                                marginTop: '20px',
                            }}
                        >
                            <FormControl variant="standard">
                                <InputLabel htmlFor="input-with-icon-adornment">
                                    Find the comic
                                </InputLabel>
                                <Input
                                    id="input-with-icon-adornment"
                                    startAdornment={
                                        <InputAdornment position="start">
                                        </InputAdornment>
                                    }
                                    value={filter}
                                    onChange={(e) => setFilter(e.target.value)}
                                />
                            </FormControl>
                        </Box>
                        <Container sx={{ py: 2 }} maxWidth="md">
                            <Grid container spacing={4}>
                                {filteredPosts
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((post, index) => (
                                        <Grid item xs={12} sm={6} md={4} key={index}>
                                            <ActionAreaImagesCard
                                                imageLink={`${BaseUrl}${post.imagem}`}
                                                title={post.name}
                                                onClick={(event) => handleImageClick(index, event)}
                                                isSelected={selectedImageNames.includes(post.name)}
                                                onSelect={() => handleImageSelect(index)}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        </Container>
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                pt: 1,
                                pb: 1,
                                textAlign: 'center',
                                marginTop: '20px',
                            }}
                        >
                            <TablePagination
                                component="div"
                                count={filteredPosts.length}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                rowsPerPageOptions={[6, 9, 15, 21]}
                                labelRowsPerPage="Images per Page"
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '20px',
                            }}
                        >
                            <Button variant="contained" color="primary" onClick={handleDownload} sx={{ mt: 2, mr: 2 }}>
                                Download Normal
                            </Button>
                            <Button variant="contained" onClick={handleDownloadNoTexto} sx={{ mt: 2, backgroundColor: 'purple' }}>
                                Download NoText
                            </Button>
                        </Box>
                    </main>
                    {openReader && (
                        <Reader
                            images={imagesPosts.map(post => `${BaseUrl}${post.imagem}`)}
                            initialIndex={selectedImageIndex}
                            onClose={handleCloseReader}
                        />
                    )}
                </Container>
            </ThemeProvider>
        </>
    );
}

export default PagesCard;
