import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Button, FormControl, Box } from '@mui/material';
import Header from '../../../components/header';
import MainFeaturedPostWrapper from '../../Home/components/MainFeaturedPostWrapper';
import mainFeaturedPost from '../../Home/components/mainFeaturedPost';
import sectionsAdmin from '../../../utils/menu/admin';
import StorieService from '../../../services/storie';
import { useDropzone } from 'react-dropzone';
import CircularIndeterminate from '../../../components/progress';
import { AlertNotification } from '../../../components/alert';

interface AlertSettingsProps {
  message: string;
  type: 'error' | 'success' | 'info' | 'warning';
}

const PageValidate: React.FC = () => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertSettings, setAlertSettings] = useState<AlertSettingsProps>({
    message: '',
    type: 'success'
  });

  const onDrop = (acceptedFiles: File[]) => {
    setUploadedFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleUpload = async () => {
    if (!uploadedFile) {
      setAlertSettings({
        message: 'Please select an image file.',
        type: 'error'
      });
      setOpenAlert(true);
      return;
    }

    try {
      setLoading(true);
      const base64Data = await convertImageToBase64(uploadedFile);
      const response = await StorieService.sendPageValidate(base64Data);
      setAlertSettings({
        message: response.data,
        type: 'success'
      });
    } catch (error) {
      setAlertSettings({
        message: error as string,
        type: 'error'
      });
    }
    setOpenAlert(true);
    setLoading(false);
  };

  const convertImageToBase64 = (image: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const value = reader.result as string;
        const base64Data = value.split(',')[1];
        resolve(base64Data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(image);
    });
  };

  const defaultTheme = createTheme();

  return (
    <>
     <CircularIndeterminate loading={loading} sx={{ mt: 2 }} />
    <AlertNotification
        message={alertSettings.message}
        type={alertSettings.type}
        alertStatus={openAlert}
        setAlertStatus={setOpenAlert} />
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <Container maxWidth="lg">
          <Header title="Welcome" sections={sectionsAdmin} />
          <main>
            <MainFeaturedPostWrapper post={mainFeaturedPost} />
            <Container sx={{ py: 2 }} maxWidth="md">
              <Box display="flex" flexDirection="column" alignItems="center">
                <FormControl fullWidth sx={{ my: 4 }}>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button variant="contained" color="primary">
                      {'Select an Image'}
                    </Button>
                  </div>
                </FormControl>
                {uploadedFile && (
                  <Box mt={2}>
                    <img src={URL.createObjectURL(uploadedFile)} alt={uploadedFile.name} style={{ maxWidth: '100%' }} />
                  </Box>
                )}
                <FormControl fullWidth sx={{ my: 2 }}>
                  <Button variant="contained" color="primary" onClick={handleUpload} disabled={loading}>
                    {loading ? 'Uploading...' : 'Upload'}
                  </Button>
                </FormControl>
              </Box>
            </Container>
          </main>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default PageValidate;
