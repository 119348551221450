import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Button, FormControl, InputLabel, LinearProgress, Select, MenuItem, SelectChangeEvent, Modal, IconButton, Checkbox, Grid } from '@mui/material';
import StorieService from '../../../services/storie';
import CircularIndeterminate from '../../../components/progress';
import { AlertNotification } from '../../../components/alert';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import Header from '../../../components/header';
import MainFeaturedPostWrapper from '../../Home/components/MainFeaturedPostWrapper';
import mainFeaturedPost from '../../Home/components/mainFeaturedPost';
import sectionsAdmin from '../../../utils/menu/admin';

interface FormData {
  images: Array<File>;
}

interface StorieModel {
  id: string;
  name: string;
}

interface SelectedStorieModel {
  id: string;
  name: string;
  pages: PageModel[]
}

interface PageModel {
  name: string;
  imagem: string;
}

const acceptedFileTypes = ['image/jpeg'];

interface AlertSettingsProps {
  message: string;
  type: 'error' | 'success' | 'info' | 'warning';
}

const UploadPages: React.FC = () => {
  const [imagemName, setImagemName] = useState<string>();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<FormData>({ images: [] });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [storie, setStorie] = useState('');
  const [stories, setStories] = useState<StorieModel[]>([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newPages, setNewPages] = useState(false);
  const [selectStorie, setSelectStorie] = useState<SelectedStorieModel | null>();
  const [alertSettings, setAlertSettings] = useState<AlertSettingsProps>({
    message: '',
    type: 'success'
  });

  const onDrop = (acceptedFiles: Array<File>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      images: acceptedFiles,
    }));
  };

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const openModal = () => {
    setUploadProgress(0);
    setFormData({ images: [] });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setStorie("");
    setSelectStorie(null);
  };

  const handleUpload = async () => {
    try {
      for (const image of formData.images) {
        const base64Data = await convertImageToBase64(image);
        const payload: PageModel = {
          imagem: base64Data,
          name: image.name,
        };
        await StorieService.includeUpdatePage(storie, newPages, payload, {
          responseType: 'blob',
          onUploadProgress: (event) => {
            setImagemName(payload.name);
            setUploadProgress((event.loaded / (event.total ?? 0)) * 100);
          },
        });
      }

      setAlertSettings({
        message: `The Pages have been saved.`,
        type: 'success'
      });
    } catch (error) {
      setAlertSettings({
        message: 'Error saving storie',
        type: 'error'
      });
    }
    setOpenAlert(true);
    setOpen(false);
  };

  const handleDeleteImage = (index: number) => {
    const updatedImages = [...formData.images];
    updatedImages.splice(index, 1);
    setFormData({ images: updatedImages });
  };

  const convertImageToBase64 = (image: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const value = reader.result as string;
        const base64Data = value.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
        resolve(base64Data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(image);
    });
  };

  const handleChange = async (event: SelectChangeEvent) => {
    setStorie(event.target.value as string);
    setLoading(true);
    try {
      var data = await StorieService.getFromId(event.target.value as string);
      setSelectStorie(data.data);
    } catch (error) {
      setAlertSettings({
        message: 'Error saving storie',
        type: 'error'
      });
      setOpenAlert(true);
      setStorie("");
    }
    setLoading(false);
  };

  const loadStoriesNames = async () => {
    try {
      const response = await StorieService.get();
      setStories(response.data);
    } catch (error) {

      setAlertSettings({
        message: 'Error saving storie',
        type: 'error'
      });
      setOpenAlert(true);
    }
    setLoading(false);
  };

  const deleteImageHandle = async (pageName: string) => {
    try {
      setLoading(true);
      await StorieService.deletePage(storie, pageName);
      var data = await StorieService.getFromId(storie);
      setSelectStorie(data.data);
    } catch (error) {
      setAlertSettings({
        message: 'Error saving storie',
        type: 'error'
      });
      setOpenAlert(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await loadStoriesNames();
    })();
  }, []);

  const defaultTheme = createTheme();

  return (
    <>
      <CircularIndeterminate loading={loading} sx={{ mt: 2 }} />
      <AlertNotification
        message={alertSettings.message}
        type={alertSettings.type}
        alertStatus={openAlert}
        setAlertStatus={setOpenAlert} />
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <Container maxWidth="lg">
          <Header title="Welcome" sections={sectionsAdmin} />
          <main>
            <MainFeaturedPostWrapper post={mainFeaturedPost} />
            <Container sx={{ py: 2 }} maxWidth="md">
              <div>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Storie Name</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={storie}
                    label="Storie Name"
                    onChange={handleChange}
                  >
                    {stories?.map((story) => (
                      <MenuItem key={story.id} value={story.id}>
                        {story.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ my: 4 }}>
                  <Button variant="contained" color="primary" onClick={openModal}>
                    {'Select the Pages to Upload'}
                  </Button>
                </FormControl>
                <Modal open={open} onClose={handleClose}>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 800,
                      height: 800,
                      bgcolor: 'background.paper',
                      boxShadow: 24,
                      p: 4,
                      maxHeight: '80vh',
                      overflow: 'auto',
                    }}
                  >
                    <Typography variant="h6" component="h2" gutterBottom>
                      Select Pages Files
                    </Typography>
                    <FormControl fullWidth sx={{ my: 2 }}>
                      <InputLabel htmlFor="newPages">New Pages</InputLabel>
                      <Checkbox
                        id="newPages"
                        checked={newPages}
                        onChange={(e) => setNewPages(e.target.checked)}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ my: 2 }}>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <FormControl fullWidth sx={{ my: 2 }}>
                          <Button variant="contained" color="primary" startIcon={<SearchIcon />}>
                            {'Select the Pages'}
                          </Button>
                        </FormControl>
                      </div>
                      {formData.images.length > 0 && (
                        <div>
                          <h4>Selected Images:</h4>
                          <ul>
                            {formData.images.map((file, index) => (
                              <li key={index} style={{ position: 'relative' }}>
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt={file.name}
                                  style={{ width: '100px', cursor: 'pointer' }}
                                />
                                <IconButton
                                  color="secondary"
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    backgroundColor: 'rgba(255, 255, 255)',
                                  }}
                                  onClick={() => handleDeleteImage(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                                <p>{file.name}</p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </FormControl>
                    <FormControl fullWidth sx={{ my: 1, display: 'flex', justifyContent: 'center' }}>
                      <Button variant="contained" color="primary" onClick={handleUpload}>
                        Save
                      </Button>
                    </FormControl>
                    {uploadProgress > 0 && ( // Mostra a barra de progresso apenas se houver progresso
                      <div>
                        <h4>Upload Progress:</h4>
                        <LinearProgress variant="determinate" value={uploadProgress} />
                        <Typography variant="body2" color="text.secondary">
                          File: {imagemName} - {uploadProgress.toFixed(2)}% Uploaded
                        </Typography>
                      </div>
                    )}
                  </Box>
                </Modal>
              </div>
            </Container>
            <Container sx={{ py: 2 }} maxWidth="md">
              <Grid container spacing={4}>
                {selectStorie?.pages.map((post, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    {/* <img
                      src={`data:image/jpeg;base64,${post.imagem}`}
                      alt={post.name}
                      style={{ width: '250px' }}
                    /> */}
                    <p>{post.name}</p>
                    <IconButton
                      color="secondary"
                      style={{ display: 'block', margin: '0 auto' }}
                      onClick={() => deleteImageHandle(post.name)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </main>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default UploadPages;
