import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Box from '@mui/material/Box';

interface ActionAreaCardProps {
    imageLink: string;
    title: string;
    free: boolean;
    lastUpdateDate: string;
    onClick: () => void;
    viewCount: number; // Adicionando a nova propriedade
}

const ActionAreaCard: React.FC<ActionAreaCardProps> = ({ imageLink, title, free, lastUpdateDate, onClick, viewCount }) => {
    return (
        <Card sx={{ height: 400 }}>
            <CardActionArea onClick={onClick}>
                <CardMedia
                    component="img"
                    height="240"
                    image={imageLink}
                    alt={title}
                />
                <CardContent>
                    <Typography gutterBottom variant="subtitle2" component="div" textAlign="center">
                        {title}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary" textAlign="center">
                        {lastUpdateDate}
                    </Typography>
                    {free && (
                        <Typography variant="subtitle2" color="green" textAlign="center">
                            Free
                        </Typography>
                    )}
                </CardContent>
            </CardActionArea>
            <Box display="flex" alignItems="center" justifyContent="center" p={1}>
                <RemoveRedEyeIcon />
                <Typography variant="subtitle2" color="text.secondary" ml={0.5}>
                    {viewCount}
                </Typography>
            </Box>
        </Card>
    );
}

export default ActionAreaCard;
