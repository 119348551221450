import instance from '..';

const get = () => {
    return instance.get(`User`);
}

const getUserId = (id: number) => {
    return instance.get(`User/get-user-id?id=${id}`);
}

const banUser = (id: string, discordMember: boolean) => {
    return instance.patch(`User/ban-user?id=${id}&discordMember=${discordMember}`);
}

const createUpdateUser = (payload: any) => {
    return instance.put(`User`, payload);
}

const removeUserHistory = (id: number) => {
    return instance.put(`User/remove-history?subscriberId=${id}`);
}

const createUnique = (id: number) => {
    return instance.post(`User/create-uniqueId?subscriberId=${id}`);
}

const UserService = {
   get,
   getUserId,
   banUser,
   createUpdateUser,
   removeUserHistory,
   createUnique
};

export default UserService;