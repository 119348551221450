import { AxiosRequestConfig } from 'axios';
import instance from '..';

const getStories = () => {
    return instance.get(`Album/get-album-read?page=1&pagesize=100`);
}

const getStorieRead = (storieName: string) => {
    return instance.get(`Album/get-comic?storieName=${storieName}`);
}

const postStorieDownload = (storieName: string, payload: any, config?: AxiosRequestConfig<any>) => {
    return instance.post(`Album/download-comic?storieName=${storieName}`, payload, { ...config });
}

const postStorieNoTextDownload = (storieName: string, payload: any, config?: AxiosRequestConfig<any>) => {
    return instance.post(`Album/download-noText?storieName=${storieName}`, payload, { ...config });
}

const getStorieFreeRead = (storieName: string) => {
    return instance.get(`Album/storie-free-read?storieName=${storieName}`);
}

const AlbumService = {
    getStories,
    getStorieRead,
    postStorieDownload,
    getStorieFreeRead,
    postStorieNoTextDownload
};

export default AlbumService;

