import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
    AppBar,
    CssBaseline,
    Grid,
    Box,
    Toolbar,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    ThemeProvider,
    createTheme,
    Button,
    Modal,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    OutlinedInput,
    IconButton,
    Checkbox,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import StorieService from '../../../services/storie';
import CircularIndeterminate from '../../../components/progress';
import { AlertNotification } from '../../../components/alert';
import MainFeaturedPostWrapper from '../../Home/components/MainFeaturedPostWrapper';
import mainFeaturedPost from '../../Home/components/mainFeaturedPost';
import sectionsAdmin from '../../../utils/menu/admin';
import Header from '../../../components/header';

interface Column {
    id: 'id' | 'name';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: any) => string;
}

const columns: Column[] = [
    { id: 'name', label: 'Name', minWidth: 150 }
]

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh',
    overflow: 'auto',
};

interface Data {
    id: string;
    name: string;
    description: string;
    cover: string;
    lastUpdate: Date;
    tierValue: number;
    enableDays: number;
    free: any;
    visible: any;
}

interface StorieModel {
    name: string;
    description: string;
    cover: string;
}

interface StorieConfig {
    tierValue: number;
    enableDays: number;
    free: any;
    visible: any;
}

function createData(
    id: string,
    name: string,
    description: string,
    cover: string,
    lastUpdate: Date,
    tierValue: number,
    enableDays: number,
    free: any,
    visible: any
): Data {
    return { id, name, description, cover, lastUpdate, tierValue, enableDays, free, visible };
}

interface AlertSettingsProps {
    message: string;
    type: 'error' | 'success' | 'info' | 'warning';
}

const StorieManager: React.FC = () => {
    const defaultTheme = createTheme();
    const [storie, setStorie] = useState<Data>({ id: "", name: "", description: "", cover: "", lastUpdate: new Date(), tierValue: 0, enableDays: 0, free: false, visible: false });
    const [filter, setFilter] = useState('');
    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState<Data[]>([]);
    const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(true);
    const [alertSettings, setAlertSettings] = useState<AlertSettingsProps>({
        message: '',
        type: 'success'
    });

    useEffect(() => {
        fetchStories();
    }, []);

    const fetchStories = async () => {
        setLoading(true);
        try {
            const data = await StorieService.get();
            setRows(data.data as Data[]);
        } catch (error) {
            setAlertSettings({
                message: 'Error fetching stories',
                type: 'error'
            });
            setOpenAlert(true);
        }
        setLoading(false);
    };

    const convertImageToBase64 = (image: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const value = reader.result as string;
                const base64Data = value.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
                resolve(base64Data);
            };
            reader.onerror = reject;
            reader.readAsDataURL(image);
        });
    };

    const onDrop = async (acceptedFiles: Array<File>) => {
        const img = await convertImageToBase64(acceptedFiles[0]);
        setStorie((prevStorie) => ({
            ...prevStorie,
            cover: img,
        }));
        setAcceptedFiles(acceptedFiles);
    };

    const clearList = () => {
        setRows([]);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRowClick = (row: Data) => {
        setStorie({ ...row });
        setEditOpen(true);
    };

    const filteredRows = rows.filter((row) =>
        row.name.toLowerCase().includes(filter.toLowerCase())
    );

    const handleClose = () => {
        setOpen(false);
        setEditOpen(false);
    };

    const handleAddRow = () => {
        const newRow: Data = createData('', '', '', '', new Date(), 0, 0, false, false);
        setStorie(newRow);
        setAcceptedFiles([]);
        setOpen(true);
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            const storieModel: StorieModel = {
                name: storie.name,
                cover: storie.cover,
                description: storie.description,
            };

            if (storie.id) {
                const storieConfigModel: StorieConfig = {
                    enableDays: storie.enableDays,
                    free: storie.free,
                    tierValue: storie.tierValue,
                    visible: storie.visible
                };

                await StorieService.updateConfig(storie.id, storieConfigModel);
                await StorieService.updateDescription(storie.id, storieModel);
            } else {
                await StorieService.create(storieModel);
            }

            setAlertSettings({
                message: `Storie ${storie.name} has been saved.`,
                type: 'success'
            });
            fetchStories();
            setOpen(false);
            setEditOpen(false);
        } catch (error) {
            setAlertSettings({
                message: 'Error saving storie',
                type: 'error'
            });
        }
        setOpenAlert(true);
        setLoading(false);
    };

    const handleDelete = async (id: string) => {
        setLoading(true);
        try {
            await StorieService.del(id);
            setAlertSettings({
                message: 'Storie has been deleted.',
                type: 'success'
            });
            fetchStories();
        } catch (error) {
            setAlertSettings({
                message: 'Error deleting storie',
                type: 'error'
            });
        }
        setOpenAlert(true);
        setLoading(false);
    };

    const handleDeleteImage = () => {
        setStorie((prevStorie) => ({
            ...prevStorie,
            cover: '',
        }));
        setAcceptedFiles([]);
    };

    return (
        <>
            <CircularIndeterminate loading={loading} sx={{ mt: 2 }} />
            <AlertNotification
                message={alertSettings.message}
                type={alertSettings.type}
                alertStatus={openAlert}
                setAlertStatus={setOpenAlert} />
            <ThemeProvider theme={defaultTheme}>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header title="Welcome" sections={sectionsAdmin} />
                    <main>
                        <MainFeaturedPostWrapper post={mainFeaturedPost} />
                        <Container maxWidth="lg">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="filter"
                                        label="Filter"
                                        variant="standard"
                                        fullWidth={true}
                                        sx={{ mb: 2 }}
                                        value={filter}
                                        onChange={handleFilterChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper sx={{ width: '100%', mb: 2 }}>
                                        <TableContainer sx={{ maxHeight: 500 }}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center" colSpan={8}>
                                                            Stories
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        {columns.map((column) => (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                style={{ top: 57, minWidth: column.minWidth }}
                                                            >
                                                                {column.label}
                                                            </TableCell>
                                                        ))}
                                                        <TableCell align="center">Actions</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {filteredRows
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((row) => {
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={-1}
                                                                    key={row.id}
                                                                >
                                                                    {columns.map((column) => {
                                                                        const value = row[column.id];
                                                                        return (
                                                                            <TableCell key={column.id} align={column.align}>
                                                                                {column.format ? column.format(value) : value}
                                                                            </TableCell>
                                                                        );
                                                                    })}
                                                                    <TableCell align="center" width="15%">
                                                                        <IconButton color="primary" onClick={() => handleRowClick(row)}>
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                        <IconButton color="secondary" onClick={() => handleDelete(row.id)}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 100]}
                                            component="div"
                                            count={filteredRows.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} display="flex" justifyContent="flex-end">
                                    <Button variant="contained" color="primary" onClick={handleAddRow}>
                                        Create Storie
                                    </Button>
                                </Grid>
                            </Grid>
                        </Container>
                        <Modal open={open} onClose={handleClose}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 800,
                                    height: 800,
                                    bgcolor: 'background.paper',
                                    boxShadow: 24,
                                    p: 4,
                                    maxHeight: '80vh',
                                    overflow: 'auto',
                                }}
                            >
                                <Typography variant="h6" component="h2" gutterBottom>
                                    Storie
                                </Typography>
                                <FormControl fullWidth sx={{ my: 2 }}>
                                    <InputLabel htmlFor="name">Name</InputLabel>
                                    <OutlinedInput
                                        id="name"
                                        value={storie.name}
                                        onChange={(e) => setStorie({ ...storie, name: e.target.value })}
                                        label="Name"
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ my: 1, display: 'flex', justifyContent: 'center' }}>
                                    <Button variant="contained" color="primary" onClick={handleSave}>
                                        Save
                                    </Button>
                                </FormControl>
                            </Box>
                        </Modal>
                        <Modal open={editOpen} onClose={handleClose}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 800,
                                    height: 800,
                                    bgcolor: 'background.paper',
                                    boxShadow: 24,
                                    p: 4,
                                    maxHeight: '80vh',
                                    overflow: 'auto',
                                }}
                            >
                                <Typography variant="h6" component="h2" gutterBottom>
                                    Edit Storie
                                </Typography>
                                <FormControl fullWidth sx={{ my: 2 }}>
                                    <InputLabel htmlFor="name">Name</InputLabel>
                                    <OutlinedInput
                                        id="name"
                                        value={storie.name}
                                        onChange={(e) => setStorie({ ...storie, name: e.target.value })}
                                        label="Name"
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ my: 2 }}>
                                    <InputLabel htmlFor="description">Description</InputLabel>
                                    <OutlinedInput
                                        id="description"
                                        value={storie.description}
                                        onChange={(e) => setStorie({ ...storie, description: e.target.value })}
                                        label="Description"
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ my: 2 }}>
                                    <InputLabel htmlFor="tierValue">Tier Value</InputLabel>
                                    <OutlinedInput
                                        id="tierValue"
                                        value={storie.tierValue}
                                        onChange={(e) => setStorie({ ...storie, tierValue: +e.target.value })}
                                        label="Tier Value"
                                        type="number"
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ my: 2 }}>
                                    <InputLabel htmlFor="enableDays">Enable Days</InputLabel>
                                    <OutlinedInput
                                        id="enableDays"
                                        value={storie.enableDays}
                                        onChange={(e) => setStorie({ ...storie, enableDays: +e.target.value })}
                                        label="Enable Days"
                                        type="number"
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ my: 2 }}>
                                    <InputLabel htmlFor="free">Free</InputLabel>
                                    <Checkbox
                                        id="free"
                                        defaultChecked={storie.free}
                                        onChange={(e) => setStorie({ ...storie, free: e.target.checked })}
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ my: 2 }}>
                                    <InputLabel htmlFor="visible">Visible</InputLabel>
                                    <Checkbox
                                        id="visible"
                                        defaultChecked={storie.visible}
                                        onChange={(e) => setStorie({ ...storie, visible: e.target.checked })}
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ my: 2 }}>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <FormControl fullWidth sx={{ my: 2 }}>
                                            <Button variant="contained" color="primary" startIcon={<SearchIcon />}>
                                                {isDragActive ? 'Drop the Pages to Upload' : 'Select the Cover'}
                                            </Button>
                                        </FormControl>
                                    </div>
                                    {acceptedFiles.length > 0 && (
                                        <div>
                                            <h4>Cover:</h4>
                                            <ul>
                                                {acceptedFiles.map((file, index) => (
                                                    <li key={index} style={{ position: 'relative' }}>
                                                        <img
                                                            src={URL.createObjectURL(file)}
                                                            alt={file.name}
                                                            style={{ width: '100px', cursor: 'pointer' }}
                                                        />
                                                        <IconButton
                                                            onClick={handleDeleteImage}
                                                            color="secondary"
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 0,
                                                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                        <p>{file.name}</p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </FormControl>
                                <FormControl fullWidth sx={{ my: 1, display: 'flex', justifyContent: 'center' }}>
                                    <Button variant="contained" color="primary" onClick={handleSave}>
                                        Save
                                    </Button>
                                </FormControl>
                            </Box>
                        </Modal>
                    </main>
                </Container>
            </ThemeProvider>
        </>
    );
}

export default StorieManager;
