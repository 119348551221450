import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Container, CssBaseline, FormControl, Grid, Input, InputAdornment, InputLabel, TablePagination } from '@mui/material';
import ActionAreaCard from '../../components/card/index';
import Header from '../../components/header';
import MainFeaturedPostWrapper from '../Home/components/MainFeaturedPostWrapper';
import mainFeaturedPost from '../Home/components/mainFeaturedPost';
import { saveToSessionStorage } from '../../utils/session';
import sections from '../../utils/menu';
import AlbumService from '../../services/album';
import CircularIndeterminate from '../../components/progress';
import { AlertNotification } from '../../components/alert';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import BaseUrl from '../../utils/Api';


const defaultTheme = createTheme();

interface FeaturePost {
    name: string,
    description: string,
    cover: string,
    lastUpdate: string,
    free: boolean,
    viewerCount: number
}

interface AlertSettingsProps {
    message: string;
    type: 'error' | 'success' | 'info' | 'warning';
}

const StorieCards: React.FC = () => {
    const [filter, setFilter] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(6);
    const [featuredPosts, setFeaturedPost] = useState<FeaturePost[]>([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [alertSettings, setAlertSettings] = useState<AlertSettingsProps>({
        message: '',
        type: 'success'
    });

    const filteredPosts = featuredPosts.filter(post =>
        post.name.toLowerCase().includes(filter.toLowerCase())
    );

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const getCookie = (name: string): string | undefined => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop()?.split(';').shift();
        }
        return undefined;
    };

    const handleAlbumClick = async (storieName: string) => {
        try {
            saveToSessionStorage("storieName", storieName);

            const uniqueId = getCookie('hasunique');
            if (uniqueId) {
                navigate("/Read");
                return;
            }

            const response = await AlbumService.getStorieFreeRead(storieName as string);
            const isFree = response.data as boolean;
            if (isFree)
                navigate("/Read");
            else
                navigate("/Authenticate");
        } catch (error) {
            const axiosError = error as AxiosError;
            const statusCode = axiosError.response?.status ?? null;
            const errorMessage = axiosError.message;

            setAlertSettings({
                message: errorMessage,
                type: statusCode == 404 ? 'warning' : 'error'
            });
            setOpenAlert(true);
        }
        saveToSessionStorage("storieName", storieName);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getStoriesAsync = async () => {
        try {
            const response = await AlbumService.getStories();
            setFeaturedPost(response.data)
        } catch (error) {
            console.error(error);
            setAlertSettings({
                message: error as string,
                type: 'error'
            });
            setOpenAlert(true);
        }
        setLoading(false);
    }

    useEffect(() => {
        getStoriesAsync();
    }, []);

    return (
        <>
            <CircularIndeterminate loading={loading} sx={{ mt: 2 }} />
            <AlertNotification
                message={alertSettings.message}
                type={alertSettings.type}
                alertStatus={openAlert}
                setAlertStatus={setOpenAlert} />
            <ThemeProvider theme={defaultTheme}>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header title="Welcome" sections={sections} />
                    <main>
                        <MainFeaturedPostWrapper post={mainFeaturedPost} />
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                pt: 1,
                                pb: 1,
                            }}
                        >
                        </Box>
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                pt: 1,
                                pb: 1,
                                textAlign: 'center', // Centraliza o conteúdo
                                marginTop: '20px', // Adiciona espaço antes do formulário
                            }}
                        >
                            <FormControl variant="standard">
                                <InputLabel htmlFor="input-with-icon-adornment">
                                    Find the comic
                                </InputLabel>
                                <Input
                                    id="input-with-icon-adornment"
                                    startAdornment={
                                        <InputAdornment position="start">
                                        </InputAdornment>
                                    }
                                    value={filter}
                                    onChange={(e) => setFilter(e.target.value)}
                                />
                            </FormControl>
                        </Box>
                        <Container sx={{ py: 2 }} maxWidth="md">
                            <Grid container spacing={4}>
                                {filteredPosts
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((post, index) => (
                                        <Grid item xs={12} sm={6} md={4} key={index}>
                                            <ActionAreaCard
                                                imageLink={`${BaseUrl}${post.cover}?${new Date().getTime()}`}
                                                title={post.name}
                                                free={post.free}
                                                lastUpdateDate={post.lastUpdate}
                                                viewCount={post.viewerCount}
                                                onClick={() => handleAlbumClick(post.name)}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        </Container>
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                pt: 1,
                                pb: 1,
                                textAlign: 'center', // Centraliza o conteúdo
                                marginTop: '20px', // Adiciona espaço antes do formulário
                            }}
                        >
                            <TablePagination
                                component="div"
                                count={filteredPosts.length}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                rowsPerPageOptions={[6, 9, 15, 21]}
                                labelRowsPerPage="Cards per Page"
                            />
                        </Box>
                    </main>
                </Container>
            </ThemeProvider>
        </>
    );
}

export default StorieCards;