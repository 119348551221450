import { AxiosRequestConfig } from 'axios';
import instance from '..';

const create = (payload: any) => {
    return instance.post(`Storie/create-storie`, payload);
}

const updateConfig = (id: string, payload: any) => {
    return instance.put(`Storie/change-storie-config?id=${id}`, payload);
}

const updateDescription = (id: string, payload: any) => {
    return instance.put(`Storie/change-storie-description?id=${id}`, payload);
}

const get = () => {
    return instance.get(`Storie`);
}

const getFromId = (id: string) => {
    return instance.get(`Storie/get-storie-id?id=${id}`);
}

const del = (id: string) => {
    return instance.delete(`Storie/delete-storie?id=${id}`);
}

const includeUpdatePage = (id: string, newPage: boolean, payload: any, config?: AxiosRequestConfig<any>) => {
    return instance.patch(`Storie/includeorupdate-page?id=${id}&newPage=${newPage}`, payload, { ...config });
}

const includeUpdateNoTextPage = (id: string, newPage: boolean, payload: any, config?: AxiosRequestConfig<any>) => {
    return instance.post(`Storie/no-text-version?id=${id}&newPage=${newPage}`, payload, { ...config });
}

const deletePage = (id: string, pageName: string, noText: boolean = false) => {
    return instance.delete(`Storie/delete-page?id=${id}&pageName=${pageName}&noText=${noText}`);
}

const sendPageValidate = (payload: any) => {
    return instance.post(`Storie/code-page`, payload);
}

const StorieService = {
    create,
    updateConfig,
    updateDescription,
    get,
    del,
    includeUpdatePage,
    getFromId,
    deletePage,
    sendPageValidate,
    includeUpdateNoTextPage
};

export default StorieService;