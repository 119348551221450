import instance from '..';

const requestScope = () => {
    return instance.get(`Authenticate/get-subscriber-code`);
}

const requestToken = (code: string) => {
    return instance.get(`Authenticate/get-token?code=${code}`);
}

const setToken = (code: string) => {
    return instance.post(`Authenticate/set-token`, code);
}

const AuthenticateService = {
    requestScope,
    requestToken,
    setToken
};

export default AuthenticateService;