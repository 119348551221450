import React, { useEffect, useState } from 'react';
import {
    CssBaseline,
    Grid,
    Box,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    ThemeProvider,
    createTheme,
    Button,
    Modal,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    OutlinedInput,
    IconButton,
    Checkbox,
    Select,
    MenuItem,
    Input,
    InputAdornment,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CircularIndeterminate from '../../components/progress';
import { AlertNotification } from '../../components/alert';
import Header from '../../components/header';
import MainFeaturedPostWrapper from '../Home/components/MainFeaturedPostWrapper';
import mainFeaturedPost from '../Home/components/mainFeaturedPost';
import sectionsAdmin from '../../utils/menu/admin';
import UserService from '../../services/user';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

interface Column {
    id: 'subscriber' | 'price' | 'active' | 'banned';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: any) => string;
}

const columns: Column[] = [
    { id: 'subscriber', label: 'Subscriber', minWidth: 150 },
    { id: 'price', label: 'Price', minWidth: 100 },
    { id: 'active', label: 'Active', minWidth: 100 },
    { id: 'banned', label: 'Banned', minWidth: 100 }
];

interface UserModel {
    subscriber: number;
    name: string;
    email: string;
    discordMember: string;
    price: number;
    subPrice: number;
    active: boolean;
    banned: boolean;
    admin: boolean;
    lastAcess: Date;
    expired: Date | null;
    payments: UserPaymentModel[];
    userHistories: UserHistoryModel[];
    userSubscribers: UserSuscribersModel[];
}

interface UserPaymentModel {
    name: string;
    price: number;
    sucess: boolean;
    authorizedAt: Date;
}

interface UserSuscribersModel {
    active: boolean;
    name: string;
}

interface UserHistoryModel {
    storieName: string;
    date: Date;
}

interface AlertSettingsProps {
    message: string;
    type: 'error' | 'success' | 'info' | 'warning';
}

const UserManager: React.FC = () => {
    const defaultTheme = createTheme();
    const [users, setUsers] = useState<UserModel[]>([]);
    const [filter, setFilter] = useState('');
    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [paymentsOpen, setPaymentsOpen] = useState(false);
    const [historyOpen, setHistoryOpen] = useState(false);
    const [subscriberOpen, setSubscriberOpen] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentUser, setCurrentUser] = useState<UserModel>({
        subscriber: 0,
        name: '',
        email: '',
        discordMember: '',
        price: 0,
        subPrice: 0,
        active: false,
        banned: false,
        admin: false,
        lastAcess: new Date(),
        expired: null,
        payments: [],
        userHistories: [],
        userSubscribers: []
    });
    const [currentUserPayments, setCurrentUserPayments] = useState<UserPaymentModel[]>([]);
    const [currentUserHistory, setCurrentUserHistory] = useState<UserHistoryModel[]>([]);
    const [currentUserSubscriber, setCurrentUserSubscriber] = useState<UserSuscribersModel[]>([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(true);
    const [alertSettings, setAlertSettings] = useState<AlertSettingsProps>({
        message: '',
        type: 'success'
    });
    const [selectedValue, setSelectedValue] = useState('');

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setSelectedValue(event.target.value);
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value);
    };

    const filteredUsers = users.filter((user) => {
        if (user.hasOwnProperty(selectedValue)) {
            return (user as any)[selectedValue].toString().includes(filter);
        }
        return false;
    });

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const data = await UserService.get();
            setUsers(data.data as UserModel[]);
        } catch (error) {
            setAlertSettings({
                message: 'Error fetching users',
                type: 'error'
            });
            setOpenAlert(true);
        }
        setLoading(false);
    };



    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRowClick = (user: UserModel) => {
        setCurrentUser(user);
        setEditOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEditOpen(false);
        setPaymentsOpen(false);
        setHistoryOpen(false);
        setSubscriberOpen(false);
    };

    const handlePaymentsOpen = async (user: UserModel) => {
        setCurrentUserPayments(user.payments);
        setPaymentsOpen(true);
    };

    const handleSubscriberOpen = async (user: UserModel) => {
        setCurrentUserSubscriber(user.userSubscribers);
        setSubscriberOpen(true);
    }

    const handleHistoryOpen = async (user: UserModel) => {
        setCurrentUserHistory(user.userHistories);
        setHistoryOpen(true);
    };

    const handleCleanHistory = async (subscriberId: number) => {
        setLoading(true);
        try {
            await UserService.removeUserHistory(subscriberId);
            setAlertSettings({
                message: `History for user ${subscriberId} has been cleared.`,
                type: 'success'
            });
            fetchUsers();
        } catch (error) {
            setAlertSettings({
                message: 'Error clearing user history',
                type: 'error'
            });
        }
        setOpenAlert(true);
        setLoading(false);
    };

    const handleAddRow = () => {
        setCurrentUser({
            subscriber: 0,
            name: '',
            email: '',
            discordMember: '',
            price: 0,
            subPrice: 0,
            active: false,
            banned: false,
            admin: false,
            lastAcess: new Date(),
            expired: null,
            payments: [],
            userHistories: [],
            userSubscribers: [],
        });
        setEditOpen(true);
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            await UserService.createUpdateUser(currentUser);
            setAlertSettings({
                message: `User ${currentUser.name} has been saved.`,
                type: 'success'
            });
            fetchUsers();
            setOpen(false);
            setEditOpen(false);
        } catch (error) {
            setAlertSettings({
                message: 'Error saving user',
                type: 'error'
            });
        }
        setOpenAlert(true);
        setLoading(false);
    };

    const handleCreateUnique = async (subscriberId: number) => {
        setLoading(true);
        try {
            const response = await UserService.createUnique(subscriberId);
            setAlertSettings({
                message: `UniqueCode: ${response.data}`,
                type: 'success'
            });

        } catch (error) {
            setAlertSettings({
                message: 'Error saving user',
                type: 'error'
            });
        }
        setOpenAlert(true);
        setLoading(false);
    }

    return (
        <>
            <CircularIndeterminate loading={loading} sx={{ mt: 2 }} />
            <AlertNotification
                message={alertSettings.message}
                type={alertSettings.type}
                alertStatus={openAlert}
                setAlertStatus={setOpenAlert} />
            <ThemeProvider theme={defaultTheme}>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Header title="Welcome" sections={sectionsAdmin} />
                    <main>
                        <MainFeaturedPostWrapper post={mainFeaturedPost} />
                        <Container maxWidth="lg">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            bgcolor: 'background.paper',
                                            pt: 1,
                                            pb: 1,
                                            textAlign: 'center',
                                            marginTop: '20px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <FormControl variant="standard" sx={{ marginRight: '16px', width: '150px' }}>
                                            <InputLabel htmlFor="demo-simple-select-standard">Select Filter</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                label="Select Filter"
                                                value={selectedValue}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {columns.map((column) => {

                                                    return (
                                                        <MenuItem key={column.id} value={column.id}>
                                                            {column.label}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>

                                        <FormControl variant="standard" sx={{ marginRight: '16px', width: '350px' }}>
                                            <InputLabel htmlFor="input-with-icon-adornment">Filter</InputLabel>
                                            <Input
                                                id="input-with-icon-adornment"
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                    </InputAdornment>
                                                }
                                                value={filter}
                                                onChange={handleFilterChange}
                                            />
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper sx={{ width: '100%', mb: 2 }}>
                                        <TableContainer sx={{ maxHeight: 500 }}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center" colSpan={3}>
                                                            Users
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        {columns.map((column) => (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                style={{ top: 57, minWidth: column.minWidth }}
                                                            >
                                                                {column.label}
                                                            </TableCell>
                                                        ))}
                                                        <TableCell align="center" style={{ width: '150px' }}>Actions</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {filteredUsers
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((user) => {
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={-1}
                                                                    key={user.subscriber}
                                                                >
                                                                    {columns.map((column) => {
                                                                        const value = user[column.id];
                                                                        const displayValue = typeof value === 'boolean' ? value ? 'True' : 'False' : value;
                                                                        return (
                                                                            <TableCell key={column.id} align={column.align}>
                                                                                {column.format ? column.format(displayValue) : displayValue}
                                                                            </TableCell>
                                                                        );
                                                                    })}
                                                                    <TableCell align="center" width="25%">
                                                                        <IconButton color="primary" onClick={() => handleRowClick(user)}>
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                        <IconButton color="secondary" onClick={() => handlePaymentsOpen(user)}>
                                                                            <AnalyticsIcon />
                                                                        </IconButton>
                                                                        <IconButton color="secondary" onClick={() => handleHistoryOpen(user)}>
                                                                            <AnnouncementIcon />
                                                                        </IconButton>
                                                                        <IconButton color="secondary" onClick={() => handleSubscriberOpen(user)}>
                                                                            <AssignmentIndIcon />
                                                                        </IconButton>
                                                                        <IconButton color="secondary" onClick={() => handleCleanHistory(user.subscriber)}>
                                                                            <AutoDeleteIcon />
                                                                        </IconButton>
                                                                        <IconButton color="primary" onClick={() => handleCreateUnique(user.subscriber)}>
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 100]}
                                            component="div"
                                            count={filteredUsers.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                                        <Button variant="contained" color="primary" onClick={handleAddRow}>
                                            Create User
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </main>
                </Container>
                <Modal
                    open={editOpen}
                    onClose={handleClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            Edit User
                        </Typography>
                        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                            <InputLabel htmlFor="price">SubscriberId</InputLabel>
                            <OutlinedInput
                                id="subscriber"
                                value={currentUser.subscriber}
                                onChange={(e) => setCurrentUser({ ...currentUser, subscriber: Number(e.target.value) })}
                                label="Subscriber"
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                            <InputLabel htmlFor="name">Name</InputLabel>
                            <OutlinedInput
                                id="name"
                                value={currentUser.name}
                                onChange={(e) => setCurrentUser({ ...currentUser, name: e.target.value })}
                                label="Name"
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                            <InputLabel htmlFor="email">Email</InputLabel>
                            <OutlinedInput
                                id="email"
                                value={currentUser.email}
                                onChange={(e) => setCurrentUser({ ...currentUser, email: e.target.value })}
                                label="Email"
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                            <InputLabel htmlFor="discordMember">Discord Member</InputLabel>
                            <OutlinedInput
                                id="discordMember"
                                value={currentUser.discordMember}
                                onChange={(e) => setCurrentUser({ ...currentUser, discordMember: e.target.value })}
                                label="Discord Member"
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                            <InputLabel htmlFor="price">Price</InputLabel>
                            <OutlinedInput
                                id="price"
                                value={currentUser.price}
                                onChange={(e) => setCurrentUser({ ...currentUser, price: Number(e.target.value) })}
                                label="Price"
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                            <InputLabel htmlFor="subprice">SubPrice</InputLabel>
                            <OutlinedInput
                                id="subprice"
                                value={currentUser.subPrice}
                                onChange={(e) => setCurrentUser({ ...currentUser, subPrice: Number(e.target.value) })}
                                label="SubPrice"
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                            <TextField
                                id="expired"
                                label="Expired Date"
                                type="datetime-local"
                                value={currentUser.expired ? currentUser.expired.toISOString().slice(0, 16) : ''}
                                onChange={(e) => {
                                    const newDate = new Date(e.target.value);
                                    if (!isNaN(newDate.getTime())) {
                                        setCurrentUser({ ...currentUser, expired: newDate });
                                    } else {
                                        console.error('Data inválida');
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <Checkbox
                                checked={currentUser.active}
                                onChange={(e) => setCurrentUser({ ...currentUser, active: e.target.checked })}
                                inputProps={{ 'aria-label': 'Active' }}
                            />
                            Active
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <Checkbox
                                checked={currentUser.banned}
                                onChange={(e) => setCurrentUser({ ...currentUser, banned: e.target.checked })}
                                inputProps={{ 'aria-label': 'Banned' }}
                            />
                            Banned
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <Checkbox
                                checked={currentUser.admin}
                                onChange={(e) => setCurrentUser({ ...currentUser, admin: e.target.checked })}
                                inputProps={{ 'aria-label': 'Admin' }}
                            />
                            Admin
                        </FormControl>
                        <Button variant="contained" onClick={handleSave}>
                            Save
                        </Button>
                    </Box>
                </Modal>
                <Modal
                    open={paymentsOpen}
                    onClose={handleClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            User Payments
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={"40%"}>Name</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell width={"10%"}>Active </TableCell>
                                        <TableCell>Authorized At</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {currentUserPayments.map((payment, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{payment.name}</TableCell>
                                            <TableCell>{payment.price}</TableCell>
                                            <TableCell>{payment.sucess ? 'Yes' : 'No'}</TableCell>
                                            <TableCell>{new Date(payment.authorizedAt).toLocaleString()}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Modal>
                <Modal
                    open={subscriberOpen}
                    onClose={handleClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description">
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            User Subscriber
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Subscriber</TableCell>
                                        <TableCell>Active</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {currentUserSubscriber.map((subscriber, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{subscriber.name}</TableCell>
                                            <TableCell>{subscriber.active ? 'Yes' : 'No'}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Modal>
                <Modal
                    open={historyOpen}
                    onClose={handleClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            User History
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Story Name</TableCell>
                                        <TableCell>Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {currentUserHistory.map((history, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{history.storieName}</TableCell>
                                            <TableCell>{new Date(history.date).toLocaleString()}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Modal>
            </ThemeProvider>
        </>
    );
};

export default UserManager;

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh',
    overflow: 'auto',
};
