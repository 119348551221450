import React from 'react'; 

const saveToSessionStorage = <T extends unknown>(key: string, value: T) => {
    try {
        const serializedValue = JSON.stringify(value);
        sessionStorage.setItem(key, serializedValue);
    } catch (error) {
        console.error('Erro ao salvar no sessionStorage:', error);
    }
};

const getFromSessionStorage = <T extends unknown>(key: string): T | null => {
    try {
        const serializedValue = sessionStorage.getItem(key);
        if (serializedValue !== null) {
            return JSON.parse(serializedValue);
        }
        return null;
    } catch (error) {
        console.error('Erro ao obter do sessionStorage:', error);
        return null;
    }
};

export { saveToSessionStorage, getFromSessionStorage };
