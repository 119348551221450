import { themeProps } from "../../theme";

export const setBackgroundColorIconClose = (
    { colors }: themeProps,
    variant?: string,
    severity?: string
  ) => {
    switch (variant) {
      case 'filled':
        switch (severity) {
          case 'error':
            return colors.primary.red;
          case 'success':
            return colors.primary.green;
          case 'warning':
            return colors.primary.yellow;
          case 'info':
            return colors.primary.blue;
        }
        break;
      case 'standard':
        switch (severity) {
          case 'error':
            return colors.generic.red[4];
          case 'success':
            return colors.generic.green[1];
          case 'warning':
            return colors.generic.yellow[1];
          case 'info':
            return colors.generic.blue[3];
        }
        break;
    }
  };