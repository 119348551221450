import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CircularIndeterminate from '../../components/progress';
import { AlertNotification } from '../../components/alert';
import AuthenticateService from '../../services/session';

interface AlertSettingsProps {
    message: string;
    type: 'error' | 'success' | 'info' | 'warning';
}

const Authenticate: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(true);
    const [alertSettings, setAlertSettings] = useState<AlertSettingsProps>({
        message: '',
        type: 'success'
    });

    const GetAuthenticateToken = async (code: string) => {
        try {
            setLoading(true);
            await AuthenticateService.requestToken(code);
            navigate("/Read");
        } catch (error) {
            setAlertSettings({
                message: 'Error getting authentication token',
                type: 'error'
            });
            setOpenAlert(true);
            navigate("/Storie")
        }
        setLoading(false);
    };

    const GetScopeAndRegister = async () => {
        try {
            const response = await AuthenticateService.requestScope();
            window.location.href = response.data;
        } catch (error) {
            setAlertSettings({
                message: 'Error getting authentication token',
                type: 'error'
            });
            setOpenAlert(true);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const searchParams = new URLSearchParams(location.search);
            const codeParam = searchParams.get('code');
            if (codeParam) {
                await GetAuthenticateToken(codeParam);
            }
            else {
                await GetScopeAndRegister();
            }
            setLoading(false);
        };

        fetchData();
    }, [location.search]);

    return (
        <>
            <CircularIndeterminate loading={loading} sx={{ mt: 2 }} />
            <AlertNotification
                message={alertSettings.message}
                type={alertSettings.type}
                alertStatus={openAlert}
                setAlertStatus={setOpenAlert} />
        </>
    );
}

export default Authenticate;
