import React, { useState } from 'react';
import { Button, FormControl, InputLabel, OutlinedInput, Box, Typography } from '@mui/material';
import AuthenticateService from '../../services/session'; // Importe seu serviço aqui
import { AlertNotification } from '../../components/alert'; // Importe o componente de alerta
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

interface AlertSettingsProps {
    message: string;
    type: 'error' | 'success' | 'info' | 'warning';
}

const Unique: React.FC = () => {
    const [keyValue, setKeyValue] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [alertSettings, setAlertSettings] = useState<AlertSettingsProps>({
        message: '',
        type: 'success'
    });


    const setCookie = (name: string, value: string, days: number) => {
        const expires = new Date();
        expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
        const expiresString = `expires=${expires.toUTCString()}`;
        document.cookie = `${name}=${value};${expiresString};path=/;secure;SameSite=None`;
    };

    const handleSend = async () => {
        setLoading(true);
        try {
            await AuthenticateService.setToken(keyValue); 
            setAlertSettings({
                message: 'Key sent successfully!',
                type: 'success'
            });

            setCookie('hasunique', 'nandofcomicreader', 1); 
            navigate("/Storie");
        } catch (error) {

            const axiosError = error as AxiosError;
            const statusCode = axiosError.response?.status ?? null;
            const errorMessage = axiosError.message;
    
            setAlertSettings({
                message: `${errorMessage}`,
                type: 'error'
            });
            setOpenAlert(true);

        } finally {
            setLoading(false);
            setOpenAlert(true);
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
        >
            <Typography variant="h4" gutterBottom>
                Enter your Key
            </Typography>
            <FormControl variant="outlined" sx={{ mb: 2, width: '300px' }}>
                <InputLabel htmlFor="key">Key</InputLabel>
                <OutlinedInput
                    id="key"
                    value={keyValue}
                    onChange={(e) => setKeyValue(e.target.value)}
                    label="Key"
                />
            </FormControl>
            <Button
                variant="contained"
                color="primary"
                onClick={handleSend}
                disabled={loading}
            >
                Send
            </Button>

            <AlertNotification
                message={alertSettings.message}
                type={alertSettings.type}
                alertStatus={openAlert}
                setAlertStatus={setOpenAlert}
            />
        </Box>
    );
};

export default Unique;
