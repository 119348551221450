import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';

interface CircularIndeterminateProps {
    loading: boolean;
    sx?: SxProps;
}

const CircularIndeterminate: React.FC<CircularIndeterminateProps> = ({ loading, sx }) => {
    if (!loading) return null;

    return (
        <Box sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.01)',
            zIndex: 1300,
            ...sx,
        }}>
            <CircularProgress />
        </Box>
    );
};

export default CircularIndeterminate;