import React from 'react';
import MainFeaturedPost from '../../../components/mainFeature';

interface MainFeaturedPostWrapperProps {
  post: {
    title: string;
    description: string;
    image: string;
    imageText: string;
    linkText: string;
  };
}

const MainFeaturedPostWrapper: React.FC<MainFeaturedPostWrapperProps> = ({ post }) => {
  return (
    <MainFeaturedPost post={post} />
  );
};

export default MainFeaturedPostWrapper;