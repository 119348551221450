import axios from 'axios';
import BaseUrl from '../utils/Api';

const instance = axios.create({
  baseURL: `${BaseUrl}api`,
  headers: {
      'Content-Type': 'application/json',
  },
  withCredentials: true, 
});

export default instance;