import { Snackbar, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import styled, { css } from 'styled-components';
import { IoMdClose } from 'react-icons/io';

import { setBackgroundColorIconClose } from '../../utils/components/alert';
import { themeProps } from '../../utils/theme';

interface AlertTypeProps {
  theme?: themeProps;
  severity?: string;
  variant?: string;
}

interface CloseIconProps {
  variant?: string;
  type?: string;
}

export const AlertContainer = styled(Snackbar)`
  &&& {
    background-color: transparent;
    margin-top: 1rem;
  }
`;

export const AlertType = styled(MuiAlert)<AlertTypeProps>`
  &&& {
    ${({ variant, severity, theme }) => css`
      display: flex;
      align-items: center;
      width: 100%;
      background-color: ${theme && theme.colors && setBackgroundColorIconClose(
        theme,
        variant,
        severity
      )};
    `}
  }
`;

export const AlertMessage = styled(Typography)`
  &&& {
    ${({ theme }) => css`
      color: ${theme && theme.colors && theme.colors.primary && theme.colors.primary.dark};
      font-size: 1.4rem;
      font-weight: 500;
    `}
  }
`;

export const CloseIcon = styled(IoMdClose)<CloseIconProps>`
  &&& {
    ${({ variant, type, theme }) => css`
      width: 2rem;
      height: 2rem;

      color: ${() => {
        if (variant === 'filled') {
          return theme && theme.colors && theme.colors.primary && theme.colors.primary.dark;
        } else {
          switch (type) {
            case 'warning':
              return theme && theme.colors && theme.colors.primary && theme.colors.primary.yellow;
            case 'error':
              return theme && theme.colors && theme.colors.primary && theme.colors.primary.red;
            case 'info':
              return theme && theme.colors && theme.colors.primary && theme.colors.primary.blue;
            case 'success':
              return theme && theme.colors && theme.colors.primary && theme.colors.primary.green;
          }
        }
      }};
    `}
  }
`;